

.everthing-wrapper {
    margin: auto;
    
}

.spots-ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 0;
    margin-top: 2.5rem;
    gap: 0.9rem;
}


#spot-container {

    margin: 0;
    width: 200px;


}

.card {
    border-radius: 15px;
    overflow:hidden;

}

#spot-image {
    width: 100%;
    height:210px;
    object-fit: cover;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;

}

.details-container {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    
}

.stars {
    text-align: right;
    padding:0;
    margin:0;
}

.stars > p {
    display:flex;
    flex-wrap:nowrap;
    margin-left: 0.3em;
}


#link-button {
    padding: 0.5rem;
    text-decoration: none;
    height: 2.3rem;
    border-radius: 5px;
    color:white;
    background-color: rgb(122, 142, 252);
    width: 50%;
    text-align: center;

}

#link-button:hover {
    background-color: rgb(87, 106, 217);

}

#view-manage-booking-btn {
    background-color: rgb(245, 124, 108);
    height: 2.3rem;
    border: none;
    color: white;
    padding: 0.2rem;
    border-radius: 5px;
    width: 100%;
}

#view-manage-booking-btn:hover {
    background-color: rgb(243, 80, 68);

}
#card-link {
   text-decoration:none;
   color: black;

}

.city-state, .price {
    font-weight: 500;
}

.update-delete-top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 0.5rem;
}

.delete-spot-modal-wrapper {
    width: 50%;
}

.delete-spot-modal-wrapper > button {
    height: 2.3rem;
    font-size: 15px;
    cursor: pointer;
    border-radius: 5px;
    background-color: gray;
    color: white;
    border: none;
    width: 100%;
}
.delete-spot-modal-wrapper > button:hover {
background-color: rgb(87, 84, 84);
}


@media screen and (min-width: 1024px){
    #spot-container{
        width: 100%;
    }
    #spot-image {
        height:325px;
    }
}

@media screen and (max-width: 975px) {
    .spots-ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
    #spot-container{
        width: 250px;
    }
    #spot-image {
        height: 230px;
    }

  }

  @media screen and (max-width: 910px) {
    #spot-container {
        width: 225px;
    }
  }

  @media screen and (max-width: 816px) {
    #spot-container {
        width: 200px;
    }
  }

  @media screen and (max-width: 750px) {
    .spots-ul {
      grid-template-columns: repeat(2, 1fr);
    }
    #spot-container{
        width: 275px;
    }
    .navigation-ul {
        display: flex;
        flex-direction: column;
    }
    .group-right{
        justify-content: space-between;
    }
  } 

  @media screen and (max-width: 690px) {
    #spot-container {
        width: 250px;
    }
  }


  @media screen and (max-width: 625px) {
    
    #spot-container {
        width: 225px;
    }
    #spot-image{
        height: 200px;
    }
  }

  
 @media screen and (max-width: 575px) {
    .spots-ul {
      grid-template-columns: 1fr;
    }
    #spot-container {
        width: 100%;
    }
    #spot-image{
        height: 280px;
    }

    .city-state, .price, .stars > p {
        font-size: 1.2rem;
    }
  }
