h1, form , input {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
}

form {
    padding: 1rem;
}

#demo-user-link, h1 {
    text-align: center;
}

.error-shown-as-typing, .red-errors {
    color: red;
}
button:not([disabled]) {
    cursor: pointer;
  }
#username-input, #password-input {
    width: 100%;
}

#btn-for-login:not([disabled]) {
    background-color: rgb(252, 99, 88);
    padding: 0.3rem;
    border: none;
    border-radius: 5px;
    color:white
}

#btn-for-login:not([disabled]):hover {
    background-color: rgb(243, 80, 68);
}
