.bookings-page-wrapper{
    display: flex;
    justify-content: center;
}

.bookings-page-subcontainer {
    display: flex;
    gap: 2rem;
    padding: 1rem;
    width: 944px;
}

.bookings-left {
    width: 48%;
    display:flex;
    flex-direction: column;
}

.bookings-right {
    border: 1px solid lightgray;
    box-shadow: 0 0 5px 1px lightgrey;
    border-radius: 15px;
    width: 48%;
    padding: 1rem;
}

.date-and-edit-wrapper {
    display:flex;
    justify-content: space-between;
    align-items: center;
}

#edit-date-btn, #hide-edit-date-btn {
    padding:none;
    margin:none;
    background-color:transparent;
    font-weight: bold;
    border: none;
}

.edit-hide-wrapper {
    display: flex;
}


.edit-date-selections {
    border: 1px solid lightgray;
    margin-top: 2rem;
    display: flex;
    border-radius: 15px;
    padding:1rem ;
    margin-bottom: 1.5rem;

}

#date-conflicts-ptag {
    width: 350px;
}

.edit-date-selections-left {
    width:50%;
    border-right: 1px solid lightgray;
    padding-right: 0.5rem;
    display:flex;
    justify-content: center;

}
.edit-date-selections-right {
    width:50%;
    padding-left: 0.5rem;
    display: flex;
    justify-content: center;
}


.edit-date-selections-left > label, .edit-date-selections-right > label {
    font-size: 10px;
    font-weight: 500;
}

.edit-date-selections-left > label > input, .edit-date-selections-right > label > input {
    border:none;
    cursor:pointer;
}



.confirm-cancel-btn-wrapper {
    margin-top: 5rem;
    display: flex;
    justify-content: space-around;
}
#confirm-btn {
    width: 10rem;
    padding: 0.5rem;
    background-color: rgb(252, 99, 88);
    color: white;
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#go-back-btn {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-radius: 8px;
    background-color: gray;
    color: white;
    border:none;
}

#go-back-btn:hover {
    background-color: rgb(87, 84, 84);
}

#confirm-btn > span, #go-back-btn > span {
    padding: 0;
    margin-right: 2px;
}

#confirm-btn:hover {
    background-color: rgb(243, 80, 68)
}

#dates-header {
    font-weight:bold;
}

.spot-description-area {
    border-bottom: 1px solid gray;
    height: 8rem;
    display: flex;
    gap: 1rem;
    padding-bottom: 1rem;
}

.left-spot-description-area {
    width: 45%;
}

.left-spot-description-area > img{
    height:100%;
    object-fit: cover;
    border-radius: 15px;

}

#rating-display-span {
    font-weight: bold;
    padding:0;
    margin-right: 5px;
}
#num-reviews-span {
    padding:0;
    color: rgb(81, 80, 80);
}

/* price area */

.price-explanation-line{
    display: flex;
    justify-content: space-between;
}

.final-total {
    border-top: 1px solid gray;
    font-weight: bold;
}

/* errors */
.booking-errors-div > p {
    color:red;
}



/* bookingsManagement.js css here */

.current-bookings-subdiv, .past-bookings-subdiv,.upcoming-bookings-subdiv  {
    border: 1px solid gray;
    padding: 2rem;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.booking-card {
    display: flex;
    padding: 0.5rem;
    gap: 1rem;
    align-items: center;
    border: 1px solid gray;
    border-radius: 15px;
}

.booking-card:hover {
    box-shadow: 2px 2px 2px gray;

}
.booking-card-image-container {
    height: 6rem;
    width: 8.5rem;
    border-radius: 15px;
    overflow:hidden;
}

.booking-card-image-container > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}

.booking-spot-name {
    font-weight: 500;
    color:black;
}

.edit-delete-booking-modal-container {
    display: flex;
    gap: 1rem;
    
}


/* booking details */
.booking-details-wrapper {
    border: 1px solid lightgray;
    box-shadow: 0 0 5px 1px lightgrey;
    display: flex;
    border-radius: 15px;
    margin-bottom: 5rem;
}
.booking-details-right, .booking-details-left {
    width: 50%;
    padding: 1rem;
}
.booking-details-right {
    border-left: 1px solid lightgray;
}

.booking-image-container > img{
    border-radius: 15px;
    object-fit: cover;
}

#spot-name-ptag {
    font-weight: bold;
}
.address-div, .host-div, .dates-div {
    display: flex;
    gap: 1rem;
}
#address-label, #host-label, #date-label {
    font-weight: 500;
}


/* GetUserBookings.js css */

.edit-booking-modal-wrapper > button {
    background-color: rgb(122, 142, 252);
    height: 2.3rem;
    font-size: 15px;
    border-radius: 5px;
    border: none;
    color: white;
}
.edit-booking-modal-wrapper > button:hover {
    background-color: rgb(87, 106, 217);

}

.cancel-booking-modal-wrapper > button {
    background-color: rgb(252, 99, 88);
    height: 2.3rem;
    font-size: 15px;
    border-radius: 5px;
    border: none;
    color: white;
}

.cancel-booking-modal-wrapper > button:hover {
    background-color: rgb(243, 80, 68)
}



@media screen and (max-width: 585px) {
    .edit-delete-booking-modal-container{
        flex-direction: column;
        gap: 0.5rem;
    }
    .edit-booking-modal-wrapper > button, .cancel-booking-modal-wrapper > button {
        width: 100%;
    }
}


@media screen and (max-width: 400px) {
    .booking-card{
        flex-direction: column;
    }
    .booking-card-left {
        width: 100%;
        height: 175px;
    }
    .booking-card-image-container {
        width: 100%;
        height: 100%;
    }
    
}
