.error{
    color:red
}

span {
    padding: 1rem;
}

.form-wrapper {
    display: flex;
    justify-content: center;
}
#spot-form {
    width: 60%;

}

#country-input, #street-address-input {
    width: 100%;
}

.city-state-div {

    display: grid;
    grid-template-columns: 60% 5% 35%
}

#city-label > input{

    width: 95%
    
}
#state-label > input, #title-label > input, #description-text-area {
    width: 100%
}

.price-container > input{
    flex:1;

}

.price-container {
    display: flex;
    gap: 1rem;
    height: 1.5rem;
    align-items: center;
}




#submitting-btn {
    width: 33%;
    margin: auto;
    background-color: rgb(252, 99, 88);
    color: white;
    padding: 0.5rem;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 15px;
}

#submitting-btn:hover {
    background-color: rgb(243, 80, 68);

}

.no-margin-top {
    margin-top: 0
}
.no-margin-bottom {
    margin-bottom: 0;
}

.uploaded-image-container {
    border: 1px solid lightgray;
    display: flex;
    height: 150px;
    gap: 1rem;
    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0 0 5px 1px lightgrey;

}

.uploaded-image-container > img {
    width: 50%;
    height: 100%;
    object-fit: cover;
    border: 1px solid lightgray;
    border-radius: 15px;
}

.upload-buttons-section {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.all-file-upload-div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 12rem;
}

.no-left-padding {
    padding-left: 0;
}


.bold500 {
    font-weight: 500;
}

.all-file-upload-div > label > input {
    width: 11.5rem;
}

.remove-image-btn {
    padding: 0.5rem;
    border-radius: 5px;
    border: none;
    background-color: gray;
    color: white;
}

.remove-image-btn:hover {
    background-color: rgb(87, 84, 84);
}

@media screen and (min-width:1250px) {
    .uploaded-image-container {
        height: 200px;
    }
}

@media screen and (min-width:1500px) {
    .uploaded-image-container {
        height: 300px;
    }
}

@media screen and (min-width: 1920px) {
    .uploaded-image-container{
        height: 375px;
    }
}


@media screen and (max-width: 715px) {
    #spot-form {
        width:90%;
    }
}
