
.spot-details-everything-wrapper {
    margin: auto;
    display: flex;
    flex-direction: column;
}


.images-container{
    display: flex;
    gap: 0.5rem;
    border-radius: 15px;
    overflow: hidden;
    height: 380px;
}

.left-img {
    height: 100%;
    width: 50%;
}

.right-imgs {
    height: 100%;
    display: flex;
    width: 50%;
}
.right-imgs-inner {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0.5rem;

}

.individual-img-containers {
    height: 184px;
    width: 100%;
}

.not-preview-imgs {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#the-preview-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.middle-section {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;
    padding-top: 2rem;
    border-bottom: 1px solid rgb(232, 221, 221);

}


.short-info-box{
    display: flex;
    flex-direction: column;
    border: 2px solid lightgray;
    box-shadow: 0 0 5px 1px lightgrey;
    padding: 0 1rem;
    border-radius: 15px;
    width: 350px;

}


.info-box-section {
    padding: 1rem 0 1rem 1rem;
    justify-self: flex-end;
}

.top-info {
    display: flex;
    gap: 1rem;

}

.rating {
    display:flex;
    align-items: center;
    gap: 0.25rem;
    flex: 1;
    justify-content: flex-end;

}

#price-section {
    flex: 1;

}

.check-in-out-date-inputs {
    flex-direction: row;
}


.reserve-button-container{
    justify-content: center;
    display:flex;
    padding: 0.5rem 0;
}

#reserve-btn {
    font-size: 15px;
    padding: 0.6rem;
    background-color: rgb(252, 99, 88);
    color: white;
    border:none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
}

#see-spot-reservations-btn {
    font-size: 15px;
    padding: 0.6rem 0;
    background-color: rgb(252, 99, 88);
    color: white;
    cursor: pointer;
    width: 100%;
    border:none;
    border-radius: 5px;
}

#reserve-btn:hover, #see-spot-reservations-btn:hover {
    background-color: rgb(243, 80, 68)
}


img {
    width: 100%;
    object-fit: cover
}

#spot-name {
    text-align:left;
}

.reserve-date-errors > p {
    color:red;
    margin: 0.2rem 0;
}

.date-selection-wrapper{
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 0.5rem 0;
    display: flex;
}

.date-selection-wrapper > label {
    font-size: 10px;
    font-weight: 500;
    width: 50%;
    padding-left: 0.5rem;
}

#reserve-area-form{
    padding: 0;
}

#checkin-label {
    border-right: 1px solid lightgray;
}
.date-selection-wrapper > label > input {
    border:none;
    cursor: pointer;
}


.post-review-btn > button {
    border: none;
    padding: 0.5rem;
    border-radius: 5px;
    color: white;
    background-color: gray;
}


.post-review-btn > button:hover {
    background-color: rgb(87, 84, 84);
}

.delete-review-modal-wrapper > button {
    border: none;
    padding: 0.5rem;
    border-radius: 5px;
    color: white;
    background-color: gray;

}

.delete-review-modal-wrapper > button:hover {
    background-color: rgb(87, 84, 84);
}


#review-text-spot-details {
    overflow-wrap:anywhere;
}

@media screen and (min-width: 1340px) {
    .images-container{
        height: 560px;
    }
    .individual-img-containers {
        height: 275px;
    }
}


@media screen and (max-width: 800px) {
    .images-container {
        flex-direction: column;
        height: fit-content;
    }
    .left-img {
        width: 100%;
    }
    .right-imgs {
        width: 100%;
    }
    .short-info-box {
        width: 325px;
    }
}

@media screen and (max-width: 715px ) {
    .middle-section{
        grid-template-columns: 1fr;
    }
    .info-box-section {
        justify-self: center;
        padding: 0 0 1rem 0;
        width: 100%;
    }
    .short-info-box {
        width: 100%;
    }
}

@media screen and (max-width: 490px) {
    .right-imgs-inner {
        grid-template-columns: 1fr;
    }
    .individual-img-containers{
        height: 100%;
    }
}
