
.reviews-container {
    border: 1px solid lightgray;
    box-shadow: 0 0 5px 1px lightgrey;
    width: 100%;
    padding: 2rem;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap:1rem;
    align-items: center;
}
.reviews-card {
    width: 100%;
    display:flex;
    border-bottom: 1px solid lightgray;
    gap: 0.5rem;
}
.spot-link-wrapper {
    border: 1px solid lightgray;
    box-shadow: 0 0 5px 1px lightgrey;

    width: 50%;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 2rem;
}

.reviews-info {

    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    margin-bottom: 1rem;
    gap:0.5rem;
}

#review-stars-container, #review-text, #date-posted-div {
    display: flex;
}

#actual-review-text {
    overflow-wrap:anywhere;
}
#image-above-address {
    border-radius: 8px;
    height: 300px;
    width: 100%
}

.bold {
    font-weight: bold;
}
.bold.add-spacing {
    margin-right: 1rem;
}

#back-to-spot-details-link {
    text-decoration: none;
    color: black;

}

.address-holder {
    display: flex;
    gap: 1rem;
}



.edit-user-review-modal-wrapper, .delete-user-review-modal-wrapper {
    justify-content: center;
    display: flex;
}


.edit-user-review-modal-wrapper > button, .delete-user-review-modal-wrapper > button {
    width: 80%;
    padding: 0.5rem;
    border:none;
    border-radius: 8px;
}

.edit-user-review-modal-wrapper > button {
    margin-bottom: 0.8rem;
    background-color: gray;
    color: white;
}

.edit-user-review-modal-wrapper > button:hover {
    background-color: rgb(87, 84, 84);
}

.delete-user-review-modal-wrapper > button {
    background-color: rgb(252, 99, 88);
    color: white;
}

.delete-user-review-modal-wrapper > button:hover {
    background-color: rgb(243, 80, 68);
}




@media screen and (min-width: 1700px) {
    #image-above-address {
        height: 400px;
    }
}

@media screen and (min-width: 2100px) {
    #image-above-address {
        height: 500px;
    }
}

@media screen and (max-width: 800px) {
    .reviews-card {
        flex-direction: column;
    }
    .spot-link-wrapper, .reviews-info{
        width: 100%;
    }
    .spot-link-wrapper{
        margin-bottom: 0
    }
    
}
