* {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

#root {
  margin: 1rem 5rem;
  
}
.hidden {
  display: none;
}

.navigation-ul li {
  list-style: none;
}

.navigation-ul {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(232, 221, 221);
  width: 100%;
}

.group-right {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}
.profile-btn:hover {
  cursor: pointer;
}

.fa-bars {
  font-size: 16px;
}

.fa-user-circle {
  color: gray;
}

.fa-user-circle:hover {
  cursor: pointer;
}

.profile-btn {
  position: relative;
}

#modal-content {
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.profile-dropdown {
  border: 2px solid lightgray;
  position: absolute;
  padding: 0.5rem;
  width: 10rem;
  background-color: white;
  text-align: center;
  right: 0;
  border-radius: 8px;
  box-shadow: 0 0 5px 1px lightgray;
}

#profile-menu-btn {
  font-size: 25px;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;
  width: 4rem;
}

#profile-menu-btn:hover {
  box-shadow: 0 0 5px 1px lightgray;
}

.home {
  font-size: 2.5rem;
}

#home-link {
  text-decoration: none;
  color: rgb(252, 99, 88);
  display: flex;
  align-items: center;
}

.my-links-section {
  font-size: 16px;
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

#github-atag,
#linkedin-atag {
  color: black;
}

#manage-spots-link,
#manage-bookings-link,
#manage-reviews-link {
  text-decoration: none;
  color: black;
}

.menu-selections {
  border-bottom: 1px solid gray;
  padding: 0.2rem 0;
  overflow-wrap:anywhere;

}

.menu-logout-btn-container {
  display: flex;
  justify-content: center;
}
#menu-logout-btn {
  margin-top: 2px;
  padding: 0.2rem 0.5rem;
  border-radius: 25px;
  background-color: rgb(252, 99, 88);
  color: white;
  cursor: pointer;
  border: none;
}

#menu-logout-btn:hover {
  background-color: rgb(243, 80, 68);
  box-shadow: 0 0 5px 1px lightgray;
}

#create-spot-navlink {
  text-decoration: none;
  font-size: 15px;
  color: black;
}

.fa-suitcase-rolling {
  padding: 1rem;
}

#logo {
  width: 60px;
  height: 60px;
}

/* search */
.search-bar-component-container {
  display: flex;
  margin: auto;
  width: 73%;
}

.search-bar-component-container > * {
  justify-content: center;
  display: flex;
  width: 100%;
}

#search-form {
  display: flex;
  width: 100%;
}

.search-input-and-icon-container {
  border: 1px solid lightgray;
  border-radius: 20px;
  overflow: hidden;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;
  box-shadow: 0 1px 3px 1px lightgrey;
}

.search-input-and-icon-container:hover {
  box-shadow: 0 1px 10px 1px lightgray;
}

#search-icon {
  padding: 0;
  cursor: pointer;
  color: gray;
}
#search-input {
  width: 90%;
  border: none;
  padding-left: 1rem;
}

#search-input:focus {
  outline: none;
}

@media screen and (max-width: 750px) {
  .profile-dropdown {
    width: 8rem;
  }
}


@media screen and (max-width: 575px) {
  #home-link, .my-links-section {
    justify-content: center;
  }
  .profile-dropdown {
    width: 7.2rem;
  }
}

@media screen and (max-width: 500px) {
  #root {
    margin: 1rem 3rem;
  }
  .profile-dropdown {
    width: 5rem;
  }

}
@media screen and (max-width: 480px) {
  #root {
    margin: 1rem 1rem;
  }
}
@media screen and (max-width: 375px) {
  #root {
    margin: 1rem 0.5rem;
  }
}
