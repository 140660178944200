.rating-input .filled {
    color: rgb(63, 106, 225);
  }
  
  .rating-input .empty {
    color: rgb(241, 239, 236);
  }

  .rating-input {
    display: flex;
    margin-right: 4px;
    justify-content: center;
    padding: 1rem;
  }
.modal-errors {
  color:red
}


#review-submit-btn:not([disabled]) {
    background-color: rgb(252, 99, 88);
    color:white
}
