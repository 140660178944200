
  #modal-content > button:nth-child(3) {
    background-color:rgb(252, 99, 88);
    color: white;
    cursor:pointer;
  }

  #modal-content > button:nth-child(3):hover {
    background-color: rgb(243, 80, 68);
  }


  #modal-content > button:nth-child(4) {
    background-color:gray;
    color: white;
    cursor:pointer;
  }

  #modal-content > button:nth-child(4):hover {
    background-color: rgb(87, 84, 84);
  }

  #modal-content > button {
    padding: 1rem;  
    margin-bottom: 1rem;
    border: none;
    border-radius: 5px;
    
  }

  #modal-content > button:not([disabled]) {
    cursor: pointer;
  }

 
